<template>
  <v-container fluid>
    <!-- <listitemsPages
      :selitem="selitem"
      :qSelect="Qselect"
      :qSelectall="Qselectall"
      :PageResponse="'reparation_pages'"
      :AllResponse="'reparation_list'"
      :title="'Reparation List'"
      :headers="headers"
      :field="'CODE'"
      :filename="'Reparation'"
      :sheetname="'Reparation'"
      :showedit="false"
      @open="open_reparation"
      @rowselect="select_reparation"
      :Add="true"
      :del="false"
      :list_options="false"
      :key="klist"
    >
    </listitemsPages> -->
    <v-row>
      <v-col cols="5">
        <listitems
          :title="'Repair List'"
          :list="reparations"
          :showedit="false"
          :headers="headers"
          :master="true"
          :add="false"
          :del="false"
          @open="open_reparation"
          @col_btn1_click="edit_reparation"
          :key="klist"
        >
        </listitems>
      </v-col>
    </v-row>

    <div>
      <reparationform
        :dialog="rep_dialog"
        :item="selitem"
        :wells="wells"
        :details="details"
        :user_list="user_list"
        @close_reparation="close_reparation"
        @refresh_reparation="refresh_reparation"
        :key="kform"
      >
      </reparationform>
    </div>
  </v-container>
</template>
<script>
//import REPARATION_PAGES from "../graphql/Reparation/Reparation_Page.gql";
//import REPARATION_LIST from "../graphql/Reparation/Reparation_List.gql";
import REPARATION_LIST from "../graphql/Reparation/REPARATIONS.gql";
export default {
  components: {
    // listitemsPages: () => import("../components/ListItemsPages.vue"),
    reparationform: () => import("../components/RepairForm.vue"),
    listitems: () => import("../components/ListItems.vue"),
  },
  data() {
    return {
      reparations: [],
      user_list: [],
      selitem: {},
      details: [],
      wells: [],
      klist: 0,
      kform: 1000,
      rep_dialog: false,
      headers: [
        {
          value: "id",
          selected: false,
        },
        {
          value: "wellname",
          text: "Well",
          selected: true,
        },
        {
          value: "nb",
          text: "Element count",
          selected: true,
        },
        {
          value: "severity",
          text: "Integrity",
          selected: true,
          slot: "progress",
          color: "color",
        },
        {
          value: "durdate",
          text: "Due Date",
          selected: true,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    // Qselect() {
    //   return REPARATION_PAGES;
    // },
    // Qselectall() {
    //   return REPARATION_LIST;
    // },
  },

  created() {
    this.getdata();
  },
  methods: {
    async getdata() {
      let r = await this.$requette(REPARATION_LIST);
      if (r) {
        this.reparations = r.data.reparation_list;
        this.wells = r.data.getWell_list;
        this.user_list = r.data.users;
        this.klist++;
      }
    },
    refresh_reparation() {
      this.getdata();
    },
    close_reparation() {
      this.rep_dialog = false;
    },
    async open_reparation() {
      this.selitem = { id: -1, well_id: -1 };
      this.details = [];
      this.rep_dialog = true;
      this.kform++;
    },
    async select_reparation(item) {
      this.selitem = item;
    },
    async edit_reparation(item) {
      this.details = [];
      this.selitem = item;
      this.rep_dialog = true;
      this.kform++;
    },
  },
};
</script>
